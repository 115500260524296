import "./Elite.scss";
import bgImage from "../assets/images/sections/19.jpg";
function EliteAwards() {
  return (
    <div className="elita" id="eliteAward">
      <div className="elita-photo">
        <img src={bgImage} alt="bgImage" />
      </div>
      <h1 className="mobile-header">
        Elite Professional Awards <span className="underline" />
      </h1>
      <div className="elita-award">
        <div className="elita-award-header">
          <h1>What is Elite Professional Award?</h1>
          <p>
            This award is presented to security industry professionals who have:
          </p>
          <div className="elita-award-list">
            <ul>
              <li>
                Dedicated a significant portion of their career to the private
                security industry in the UAE, and;
              </li>
              <li>
                Created an example to follow in their professional behavior and
                actions, and;
              </li>
              <li>
                Actively developed the UAE’s security industry through their
                efforts and perseverance.
              </li>
            </ul>
          </div>
        </div>
        <div className="elita-award-types-item">
          <h3>Applies To</h3>
          <p>
            Any person who has worked in the private security sector in the UAE
            and provided distinguished service to the industry.
          </p>
        </div>
        <div className="elita-award-types-item">
          <h3>Rules</h3>
          <p>
            Sponsors and private security industry stakeholders will make
            nominations for the Elite Professional Award.
          </p>
        </div>
        <div className="elita-award-types-item">
          <h3>Evaluation Criteria</h3>
          <ul>
            <li>Nomination letter from the Award's Sponsors and Partners</li>
            <li>Evaluation panel selects one nominee</li>
          </ul>
        </div>
        <div className="elita-award-types-item">
          <h3>How to apply?</h3>
          <p>Send a nomination letter from the Award's Sponsors and Partners</p>
        </div>
      </div>
    </div>
  );
}

export default EliteAwards;
