import { useEffect } from "react";
import "./App.scss";
import Hero from "./hero/Hero";
import About from "./about/About";
import EliteAwards from "../Elite/Elite";
import Sponsors from "./sponsors/Sponsors";
import Header from "../Shared/header/Header";
import Footer from "../Shared/footer/Footer";
import ExcellencyAwards from "../Excellency/Excellency";
import OutstandingAward from "../Outstanding/Outstanding";

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window.location.href = "#home";
  }, []);

  return (
    <div>
      <Header />
      <Hero />
      <About />
      <Sponsors />
      <EliteAwards />
      <OutstandingAward />
      <ExcellencyAwards />
      <Footer />
    </div>
  );
}

export default App;
