import Footer from "../Shared/footer/Footer";
import Header from "../Shared/header/Header";
import './policy.scss'
function PrivacyPolicy() {
    return (
        <div>
            <Header />
            <div className="container">
                <h1>Privacy Policy</h1>
                <p><strong>Last updated: 03/January/2024</strong></p>

                <h2>Introduction</h2>
                <p>Security Professionalism & Excellence Awards ("we", "us", "our") respects the privacy of its users ("you") and is committed to protecting it through our compliance with this policy.</p>
                <p>This policy describes:</p>
                <ul>
                    <li>The types of information we may collect or that you may provide when you access or use SPEA Awards.</li>
                    <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
                </ul>

                <h2>Information We Collect and How We Collect It</h2>
                <p>We collect several types of information from and about users of our Website, including information:</p>
                <ul>
                    <li>By which you may be personally identified, such as name, postal address, email address, telephone number, or any other identifier by which you may be contacted online or offline ("personal information").</li>
                    <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
                </ul>
                <p>We collect this information:</p>
                <ul>
                    <li>Directly from you when you provide it to us.</li>
                    <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
                </ul>
                <h2>How We Use Your Information</h2>
                <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
                <ul>
                    <li>To present our Website and its contents to you.</li>
                    <li>To provide you with information, products, or services that you request from us.</li>
                    <li>To fulfill any other purpose for which you provide it.</li>
                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
                </ul>
                <h2>Disclosure of Your Information</h2>
                <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
                <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
                <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>To contractors, service providers, and other third parties we use to support our business.</li>
                    <li>To fulfill the purpose for which you provide it.</li>
                </ul>
                <h2>Changes to Our Privacy Policy</h2>
                <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you through a notice on the Website home page.</p>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;