import IndustrySector from "../../icons/IndustrySector";
import SecurityCreating from "../../icons/SecurityCreating";
import SecurityGroup from "../../icons/SecurityGroup";
import SecurityIcon from "../../icons/SecurityIcon";
import "./About.scss";

function About() {
  return (
    <div className="about">
      <div className="about-spea">
        <div className="about-spea-details">
          <h1>
            About SPEA <span className="underline" />
          </h1>
          <p>
            Under the auspices of the Security Industry Regulatory Agency, the
            Security Professionalism and Excellence Award is being launched. It
            is an award that supports governmental and private security agencies
            and institutions that believe in the importance of the security
            industry sector and its contributions to preserving the security and
            safety of society and its gains. This is due to the importance of
            this sector in growth and prosperity and its close connection with
            all other sectors. The award is considered a tribute and
            appreciation to workers in the security sector who make
            distinguished efforts to spread security, reassurance and peace.
          </p>
          <div className="background-overlay" />
        </div>
      </div>
      <div className="goals">
        <h3>
          Our Goals <span className="underline" />
        </h3>
        <ul>
          <li>
            <SecurityGroup />
            <span>
              Highlighting the distinguished and honorable security role of the
              deserving members of the concerned groups.
            </span>
          </li>
          <li>
            <SecurityCreating />
            <span>
              Creating ideal role models in the security industry sector and
              identifying the level of security performance that communities
              aspire to.
            </span>
          </li>
          <li>
            <SecurityIcon />
            <span>
              Motivating the business sector to attract qualified security
              personnel, develop them, and create a good opportunity for them.
            </span>
          </li>
          <li>
            <IndustrySector />
            <span>
              Creating a competitive atmosphere among companies operating in the
              security industry sector.
            </span>
          </li>
        </ul>
        {/* <span>* Highlighting the distinguished and honorable security role of the deserving concerned groups, which is a role model in the security industry sector.</span>
                <span>* Creating ideal role models in the security industry sector and identifying the level of security performance that high-end societies aspire to. </span>
                <span>* Motivating the business sector to attract qualified security personnel, develop them, and create a good opportunity for them.</span>
                <span>* Creating a competitive atmosphere among companies operating in the security industry sector.</span> */}
      </div>
    </div>
  );
}

export default About;
