import React from "react";

const SecurityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M14.0869 1.92456C12.1391 2.22601 10.8173 2.6434 9.24054 3.45499C5.56517 5.33325 2.9101 8.8347 2.01735 12.9854C1.7159 14.3535 1.7159 17.1825 2.01735 18.5506C3.19996 24.0463 7.37387 28.2202 12.8695 29.4028C14.2376 29.7043 17.0666 29.7043 18.4347 29.4028C23.9304 28.2202 28.1043 24.0347 29.2869 18.5506C29.5536 17.3217 29.5884 14.4695 29.3449 13.2753C28.7884 10.4347 27.4782 7.97673 25.4608 5.95934C23.5362 4.0347 21.2869 2.78253 18.6666 2.19122C17.7043 1.97093 14.8753 1.80862 14.0869 1.92456ZM18.0405 2.85209C20.6376 3.30427 23.026 4.56804 24.9391 6.48108C30.9913 12.5333 29.7391 22.6202 22.3652 27.0724C19.0492 29.0782 14.7942 29.4492 10.9913 28.0579C8.47532 27.1419 6.16807 25.2869 4.66083 22.968C1.25213 17.7391 1.95938 10.8869 6.36517 6.48108C9.44923 3.39702 13.6811 2.07528 18.0405 2.85209Z"
        fill="#D4AE62"
      />
      <path
        d="M15.374 6.56225C15.2349 6.68978 15.1885 6.96805 15.1885 7.54776C15.1885 8.4521 15.316 8.81152 15.6522 8.81152C15.9885 8.81152 16.116 8.4521 16.116 7.54776C16.116 6.77094 15.9537 6.37674 15.6522 6.37674C15.5943 6.37674 15.4667 6.4579 15.374 6.56225Z"
        fill="#D4AE62"
      />
      <path
        d="M13.4955 7.58235C11.3042 8.39394 9.9593 9.8664 9.22887 12.2548C9.14771 12.5099 9.1709 12.5215 9.79699 12.5215C10.5738 12.5215 11.0608 12.7534 11.3042 13.2171C11.8144 14.2142 11.0955 15.0722 9.75061 15.0722H9.1709L9.35641 15.6751C9.44916 16.0113 9.63467 16.4983 9.7622 16.7534C10.0405 17.2867 10.6781 18.2026 10.7709 18.2026C10.8057 18.2026 10.968 17.9708 11.1187 17.6809C11.4897 16.9737 12.255 16.2896 12.9506 16.0461L13.5303 15.8374L13.1709 15.2693C12.8463 14.7476 12.8115 14.5852 12.8115 13.7968C12.8115 13.0084 12.8463 12.8461 13.1593 12.336C14.3303 10.4461 16.9738 10.4461 18.1448 12.336C18.4579 12.8461 18.4926 13.0084 18.4926 13.7968C18.4926 14.5852 18.4579 14.7476 18.1332 15.2693L17.7738 15.8374L18.3187 16.0345C19.0144 16.278 19.826 16.9968 20.1854 17.6693C20.3361 17.9592 20.4984 18.2026 20.5448 18.2026C20.7883 18.2026 21.681 16.6026 21.9477 15.6983L22.1216 15.0954L21.3796 15.049C20.5216 14.991 20.0579 14.7244 19.8955 14.1563C19.6289 13.2287 20.3013 12.5215 21.4723 12.5215C22.1332 12.5215 22.1564 12.5099 22.0752 12.2548C21.2752 9.64611 19.8028 8.17365 17.1941 7.37365C16.939 7.29249 16.9274 7.31568 16.9274 7.8838C16.9274 8.64901 16.6839 9.21713 16.2434 9.43742C15.2347 9.95916 14.3767 9.25191 14.3767 7.8838C14.3767 7.57075 14.3419 7.30409 14.2955 7.31568C14.2376 7.31568 13.8781 7.43162 13.4955 7.58235Z"
        fill="#D4AE62"
      />
      <path
        d="M15.0493 11.8145C14.1334 12.1159 13.4957 13.0666 13.6 13.9826C13.716 15.0492 14.5855 15.8145 15.6522 15.8145C16.7189 15.8145 17.5884 15.0492 17.7044 13.9826C17.7855 13.2985 17.4029 12.4985 16.8232 12.1043C16.3479 11.8029 15.5363 11.6637 15.0493 11.8145Z"
        fill="#D4AE62"
      />
      <path
        d="M8.40581 13.5188C8.24349 13.6811 8.24349 13.7391 8.38262 13.9594C8.52175 14.1681 8.68407 14.2029 9.37972 14.2376C10.2725 14.2724 10.6667 14.1449 10.6667 13.7971C10.6667 13.4608 10.3073 13.3333 9.40291 13.3333C8.81161 13.3333 8.54494 13.3797 8.40581 13.5188Z"
        fill="#D4AE62"
      />
      <path
        d="M20.8464 13.4608C20.5682 13.6231 20.5798 13.9941 20.8696 14.1449C20.9971 14.2144 21.4725 14.2608 21.9247 14.2376C22.6203 14.2028 22.7827 14.1681 22.9218 13.9594C23.0609 13.7391 23.0609 13.6811 22.8986 13.5188C22.6783 13.2985 21.1827 13.2637 20.8464 13.4608Z"
        fill="#D4AE62"
      />
      <path
        d="M13.5653 16.6839C12.7537 16.939 12.1624 17.4491 11.7566 18.2028C11.5711 18.539 11.5363 18.9332 11.5015 20.6491C11.4667 22.4231 11.4899 22.7129 11.6522 22.8752C11.8725 23.0955 12.0348 23.1187 12.2899 22.9564C12.429 22.8752 12.4754 22.4926 12.5218 21.1941L12.5798 19.5361H12.9276H13.2754L13.3102 22.0752C13.3218 23.71 13.3798 24.6723 13.4609 24.7767C13.6464 24.997 17.658 24.997 17.8435 24.7767C17.9247 24.6723 17.9827 23.71 17.9943 22.0752L18.029 19.5361H18.3769H18.7247L18.7827 21.1941C18.829 22.4926 18.8754 22.8752 19.0145 22.9564C19.2696 23.1187 19.4319 23.0955 19.6522 22.8752C19.8145 22.7129 19.8377 22.4231 19.803 20.6491C19.7682 18.4115 19.6638 18.052 18.9334 17.3796C18.2145 16.7071 17.9595 16.6375 15.8261 16.6144C14.7479 16.6028 13.7276 16.6375 13.5653 16.6839Z"
        fill="#D4AE62"
      />
    </svg>
  );
};

export default SecurityIcon;
