import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./Home/App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import OutstandingAward from "./Outstanding/Outstanding";
import EliteAwards from "./Elite/Elite";
import ExcellencyAwards from "./Excellency/Excellency";
import PrivacyPolicy from "./policy/policy";
import TermsAndConditions from "./terms/terms";
import Media from "./Media/Media";
import { Media2024 } from "./Media/Media2024/Media2024";

const router = createBrowserRouter([
  {
    path: "/",
    Component: App,
  },
  {
    path: "outstanding",
    Component: OutstandingAward,
  },
  {
    path: "elite",
    Component: EliteAwards,
  },
  {
    path: "excellence",
    Component: ExcellencyAwards,
  },
  {
    path: "policy",
    Component: PrivacyPolicy
  },
  {
    path: 'terms',
    Component: TermsAndConditions
  },
  {
    path: "media",
    Component: Media,
  },
  {
    path: "media/2024",
    Component: Media2024,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <div className="container">
      <RouterProvider router={router} />
    </div>
  </div>
);

reportWebVitals();
