import "./Excellency.scss";
import bgImage from "../assets/images/sections/17.jpg";

function ExcellencyAwards() {
  return (
    <div className="excellence" id="excellenceAward">
      <div className="excellence-photo">
        <img src={bgImage} alt="bgImage" />
      </div>
      <h1 className="mobile-header">
        Excellence Awards <span className="underline" />
      </h1>
      <div className="excellence-awards">
        <div className="excellence-awards-header">
          <h1>What is Excellence Awards?</h1>
          <p>For people who have demonstrated high effeciency in their work.</p>
        </div>
        <div className="excellence-awards-item">
          <h3>Applies To</h3>
          <ul>
            <li>Security Manager</li>
            <li>Security Operations Manager</li>
            <li>Security Systems Engineer</li>
          </ul>
        </div>
        <div className="excellence-awards-item">
          <h3>Rules</h3>
          <ul>
            <li>
              The application is submitted by the candidate him/herself
              (self-nomination).
            </li>
          </ul>
        </div>
        <div className="excellence-awards-item">
          <h3>Evaluation Criteria</h3>
          <ul>
            <li>Nomination Form</li>
            <li>Evaluation Comittee</li>
            <li>Online Test</li>
          </ul>
        </div>
        <div className="excellence-awards-item">
          <h3>Nomination Form</h3>
          <a
            className="download-btn"
            href="Nomination Form.pdf"
            target="_blank"
          >
            Download Here
          </a>
        </div>
      </div>
    </div>
  );
}

export default ExcellencyAwards;
