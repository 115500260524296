import "./Media2024.scss";
import React, { useState } from "react";
import Header from "../../Shared/header/Header";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export const Media2024 = () => {
  const [pictures, setPictures] = useState([
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2906.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2901.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2895.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2891.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2885.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2881.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2879.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2851.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2837.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2829.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2827.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2824.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2817.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2813.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2806.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2805.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2802.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2796.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2792.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/DSC_2789.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7810.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7787.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7781.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7774.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7768.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7764.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7762.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7740.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7733.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7724.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7717.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7704.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7697.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7692.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7688.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7686.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7676.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7661.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7659.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7658.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7651.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7639.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7638.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7633.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7631.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7625.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7613.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7607.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7598.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7596.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7580.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7541.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7536.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7530.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7507.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7498.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7478.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7475.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7468.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7443.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7441.jpg",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/pics/0C2A7438.jpg",
  ]);

  const [videos, setVideos] = useState([
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/videos/The+SPEA+Awards+2024.mp4",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/videos/SPE+Award+Cermony.mp4",
    "https://spea-files.s3.me-central-1.amazonaws.com/website/HD+OFFICIAL.mov",
    "https://spea-files.s3.me-central-1.amazonaws.com/media/2024/videos/haroon+Screen+3360x1008.mp4",
  ]);

  return (
    <>
      <Header />
      <div className="media-container">
        <Tabs className="tabs">
          <TabList>
            <Tab>Pictures</Tab>
            <Tab>Videos</Tab>
          </TabList>

          <TabPanel className="picture">
            {pictures.map((elm, index) => {
              return (
                <Zoom key={index}>
                  <div className="media-item">
                    <img src={elm} alt="" />
                  </div>
                </Zoom>
              );
            })}
          </TabPanel>
          <TabPanel className="picture">
            {videos.map((elm, index) => {
              return (
                <div key={index} className="media-item">
                  <video width="100%" height="100%" controls src={elm} />
                </div>
              );
            })}
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};
