import { useEffect, useState } from "react";
import "./Header.scss";
import websiteLogo from "../../assets/images/logo-full-gold.png";
import menuImg from "../../assets/images/menu.png";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

function Header() {
  const [currentLocation, setCurrentLocation] = useState("home");
  const [menuStatus, setMenuStatus] = useState(false);
  const loc = useLocation();
  const navigate = useNavigate();

  const handleNavigationClick = (id, location) => {
    console.log("loc.pathname", loc);
    if (loc.pathname.includes("/media")) {
      return navigate("/" + loc.hash);
    }
    const section = document.getElementById(id);
    if (section) {
      if (id === "headers") {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        const rect = section.getBoundingClientRect();
        window.scroll({
          top: rect.top + window.scrollY - 79,
          behavior: "smooth",
        });
      }
    }
    setCurrentLocation(location);
  };

  useEffect(() => {
    if (loc.pathname === "/") {
      switch (window.location.hash) {
        case "#home":
          handleNavigationClick("headers", "");
          break;
        case "#elite":
          handleNavigationClick("eliteAward", "elite");
          break;
        case "#outstanding":
          handleNavigationClick("outstandingAward", "outstanding");
          break;
        case "#excellence":
          handleNavigationClick("excellenceAward", "excellence");
          break;
        default:
          handleNavigationClick("headers", "");
          return;
      }
    }
  }, [loc]);

  return (
    <header className="header" id="headers">
      <div
        className="website-logo"
        onClick={() => handleNavigationClick("headers", "")}
      >
        <img className="logo" src={websiteLogo} alt="logo full" />
      </div>
      <img
        src={menuImg}
        className="burger"
        onClick={() => setMenuStatus(!menuStatus)}
      />
      <div className="navbar">
        <nav
          className={
            "header-navigation " + (menuStatus ? "expanded" : "collapsed")
          }
        >
          <ul>
            <li
              className={
                currentLocation === "home" && loc.pathname === "/"
                  ? "active"
                  : ""
              }
            >
              <a
                href="#home"
                onClick={() => handleNavigationClick("headers", "home")}
              >
                Home
              </a>
            </li>
            <li className={currentLocation === "elite" ? "active" : ""}>
              <a
                href="#elite"
                onClick={() => handleNavigationClick("eliteAward", "elite")}
              >
                Elite Professional Awards
              </a>
            </li>
            <li className={currentLocation === "outstanding" ? "active" : ""}>
              <a
                href="#outstanding"
                onClick={() =>
                  handleNavigationClick("outstandingAward", "outstanding")
                }
              >
                Outstanding Awards
              </a>
            </li>
            <li className={currentLocation === "excellence" ? "active" : ""}>
              <a
                href="#excellence"
                onClick={() =>
                  handleNavigationClick("excellenceAward", "excellence")
                }
              >
                Excellence Awards
              </a>
            </li>
            <li className={loc.pathname==="/media"?"active":""}>
              <a href="/media">Media</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
