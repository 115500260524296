import "./Sponsors.scss";
import SiriaLogo from "../../assets/images/sponsors/sira-logo.jpg";
import SpaLogo from "../../assets/images/sponsors/spa-logo.png";
import IcscLogo from "../../assets/images/sponsors/icsc-logo.jpg";
import UxeLogo from "../../assets/images/sponsors/uxe_logo.png";
import AlameenLogo from "../../assets/images/sponsors/alameen_logo.png";
import CityGuildsLogo from "../../assets/images/sponsors/city-guilds-logo.jpg";
import WstpLogo from "../../assets/images/sponsors/wstp-logo.jpg";
import IsjLogo from "../../assets/images/sponsors/isj-logo.png";
import CsypLogo from "../../assets/images/sponsors/csyp-logo.png";
import SafeerLogo from "../../assets/images/sponsors/safeer-logo.jpg";
import TsiLogo from "../../assets/images/sponsors/tsi-logo.png";
import TentechLogo from "../../assets/images/sponsors/tentech-logo.jpg";
import IesLogo from "../../assets/images/sponsors/ies-logo.png";
import MesseLogo from "../../assets/images/sponsors/messe-logo.png";
import AmanLogo from "../../assets/images/sponsors/aman-logo.jpg";
import InfiniteLooper from "../../InfiniteLooper/InfiniteLooper";

const sponsorsData = [
  {
    id: 1,
    url: SiriaLogo,
  },
  {
    id: 2,
    url: SpaLogo,
  },
  {
    id: 3,
    url: IcscLogo,
  },
  {
    id: 4,
    url: UxeLogo,
  },
  {
    id: 5,
    url: AlameenLogo,
  },
  {
    id: 6,
    url: CityGuildsLogo,
  },
  {
    id: 7,
    url: WstpLogo,
  },
  {
    id: 8,
    url: IsjLogo,
  },
  {
    id: 9,
    url: CsypLogo,
  },
  {
    id: 10,
    url: SafeerLogo,
  },
  {
    id: 11,
    url: TsiLogo,
  },
  {
    id: 12,
    url: TentechLogo,
  },
  {
    id: 13,
    url: IesLogo,
  },
  {
    id: 14,
    url: MesseLogo,
  },
  {
    id: 15,
    url: AmanLogo,
  },
];

function Sponsors() {
  return (
    <div className="sponsors">
      <h1 className="sponsors-title">
        Our Partners & Sponsors <span className="underline" />
      </h1>
      <div className="logos">
        <InfiniteLooper direction="left" speed={16}>
          {sponsorsData.length &&
            sponsorsData?.map((item) => {
              return (
                <div key={item.id}>
                  <img src={item.url} alt="logo" />
                </div>
              );
            })}
        </InfiniteLooper>
      </div>
    </div>
  );
}

export default Sponsors;
