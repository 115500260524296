import React from "react";
import "./Hero.scss";
import landingVideo from "../../assets/video/landing.mp4";

const Hero = () => {
  return (
    <div className="hero_section">
      <video autoPlay loop muted playsInline>
        <source src={landingVideo} type="video/mp4"></source>
      </video>
    </div>
  );
};

export default Hero;
