import "./Outstanding.scss";
import bgImage from "../assets/images/sections/18.jpg";

function OutstandingAward() {
  return (
    <div className="outstanding" id="outstandingAward">
      <div className="outstanding-photo">
        <img src={bgImage} alt="bgImage" />
      </div>
      <h1 className="mobile-header">
        Outstanding Awards <span className="underline" />
      </h1>
      <div className="outstanding-awards">
        <div className="outstanding-awards-header">
          <h1>What is Outstanding Awards?</h1>
          <p>
            This award is presented to security industry professionals in
            specific functions who have:
          </p>
          <div className="outstanding-awards-list">
            <ul>
              <li>Excelled in their specific field, and;</li>
              <li>
                Worked hard and diligently for the security service provider or
                commercial establishment that employs them.
              </li>
            </ul>
          </div>
        </div>
        <div className="outstanding-awards-item">
          <h3>Applies To</h3>
          <ul>
            <li>Security Guard (Male)</li>
            <li>Security Guard (Female)</li>
            <li>CVIT Guard</li>
            <li>Control Room Operator</li>
            <li>Security Supervisor</li>
            <li>Security Systems Technician</li>
          </ul>
        </div>
        <div className="outstanding-awards-item">
          <h3>Rules</h3>
          <ul>
            <li>
              Security service providers and commercial establishments in the
              UAE may nominate current employees for the Outstanding Award.
            </li>
            <li>
              For every branch, the company has within the UAE, the security
              service provider/commercial establishment may nominate one
              candidate per category.
            </li>
          </ul>
        </div>
        <div className="outstanding-awards-item">
          <h3>Evaluation Criteria</h3>
          <ul>
            <li>Recommendation Form</li>
            <li>Evaluation Comittee</li>
            <li>Online Test</li>
          </ul>
        </div>
        <div className="outstanding-awards-item">
          <h3>Recommendation Form</h3>
          <a
            className="download-btn"
            href="Recommendation Form.pdf"
            target="_blank"
          >
            Download Here
          </a>
        </div>
      </div>
    </div>
  );
}

export default OutstandingAward;
