import Footer from "../Shared/footer/Footer";
import Header from "../Shared/header/Header";

function TermsAndConditions(){
    return(
        <div>
            <Header/>

            <Footer/>
        </div>
    )
}

export default TermsAndConditions;