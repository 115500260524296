import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-section">
      <Link to="/policy">Privacy Policy</Link> – Copyright ©{"2024"}
      {new Date().getFullYear()}
    </div>
  );
};
{/* <Link to="/terms">Terms & Conditions</Link> – */}
export default Footer;
