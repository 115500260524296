import React from "react";

const IndustrySector = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="32"
      viewBox="0 0 39 32"
      fill="none"
    >
      <path
        d="M16.9037 1.32809L16.1275 2.65624L14.9201 2.96671C14.2646 3.1392 12.8502 3.70841 11.7808 4.24311L9.86623 5.19179L8.38284 4.79507L6.89946 4.39835L5.6748 5.62301L4.4674 6.83041L4.86412 8.33104L5.26084 9.83168L4.51915 11.1943C4.12243 11.936 3.53597 13.3504 3.20825 14.3336L2.63904 16.1274L1.3109 16.9036L0 17.6798V19.4219V21.1813L1.3109 21.923L2.63904 22.6819L3.2255 24.493C3.53597 25.4762 4.10518 26.8561 4.48465 27.5633C4.86412 28.2532 5.17459 28.9432 5.17459 29.0812C5.17459 29.3916 5.1401 29.3916 6.27851 28.9604L7.22718 28.5982L6.70972 27.822C3.94994 23.8203 3.43248 17.7488 5.48507 13.2297C6.93396 10.0387 9.59025 7.26163 12.6778 5.74375C16.9899 3.60491 21.992 3.60491 26.3042 5.74375C29.3917 7.26163 32.048 10.0387 33.4969 13.2297C34.7733 16.0757 35.1355 20.1636 34.3421 23.0959C33.9108 24.7518 33.0657 26.6836 32.2722 27.822L31.7548 28.5982L32.7034 28.9604C33.8418 29.3916 33.8073 29.3916 33.8073 29.0812C33.8073 28.9432 34.1351 28.236 34.5318 27.4943C34.9285 26.7698 35.4977 25.3727 35.7909 24.424L36.3429 22.6819L37.6538 21.923L38.9819 21.1813V19.4219V17.6798L37.671 16.9036L36.3429 16.1274L35.7737 14.3336C35.446 13.3504 34.8595 11.936 34.4628 11.1943L33.7211 9.83168L34.1178 8.33104L34.5145 6.83041L33.3071 5.62301L32.0825 4.39835L30.5991 4.79507L29.1157 5.19179L27.2011 4.24311C26.1317 3.70841 24.7346 3.1392 24.0619 2.96671L22.8545 2.65624L22.0783 1.32809L21.3021 -5.30252e-05L19.491 0.0171956H17.6799L16.9037 1.32809Z"
        fill="#D4AE62"
      />
      <path
        d="M18.8008 17.7491V26.3389L20.8361 26.4596C22.8025 26.5804 26.1487 26.9944 26.6489 27.2013C26.8731 27.2703 26.9076 26.0974 26.9076 18.2148V9.142H22.8542H18.8008V17.7491ZM22.2505 13.0229V14.3338L20.9224 14.2821L19.577 14.2304L19.5252 13.2127C19.4562 11.7293 19.4562 11.7293 20.9569 11.7293H22.2505V13.0229ZM26.0452 13.0229V14.3166H24.7516H23.4579V13.0229V11.7293H24.7516H26.0452V13.0229ZM22.1643 17.2489V18.5425L20.9741 18.5943C20.1289 18.6288 19.7322 18.577 19.646 18.4218C19.4217 18.0768 19.4735 16.3002 19.6977 16.076C19.8357 15.938 20.3187 15.869 21.0431 15.9035L22.1643 15.9552V17.2489ZM26.0107 17.2834L26.0625 18.6288H24.7516H23.4579V17.3696C23.4579 16.6624 23.5097 16.0415 23.5787 15.9725C23.6477 15.9035 24.2169 15.869 24.8378 15.9035L25.959 15.9552L26.0107 17.2834ZM22.1643 21.561V22.8547L21.0776 22.9064C19.6805 22.9754 19.4907 22.8202 19.4907 21.6473C19.4907 20.1811 19.5252 20.1639 20.9396 20.2156L22.1643 20.2674V21.561ZM25.959 21.561V22.8547H24.7516H23.5442L23.4924 21.7508C23.4234 20.1639 23.4234 20.1639 24.7861 20.2156L25.959 20.2674V21.561Z"
        fill="#D4AE62"
      />
      <path
        d="M20.3535 13.0229C20.3535 13.3851 20.4398 13.4541 20.871 13.4541C21.3022 13.4541 21.3884 13.3851 21.3884 13.0229C21.3884 12.6607 21.3022 12.5917 20.871 12.5917C20.4398 12.5917 20.3535 12.6607 20.3535 13.0229Z"
        fill="#D4AE62"
      />
      <path
        d="M24.1479 13.0229C24.1479 13.3851 24.2342 13.4541 24.6654 13.4541C25.0966 13.4541 25.1829 13.3851 25.1829 13.0229C25.1829 12.6607 25.0966 12.5917 24.6654 12.5917C24.2342 12.5917 24.1479 12.6607 24.1479 13.0229Z"
        fill="#D4AE62"
      />
      <path
        d="M20.3535 17.2487C20.3535 17.7144 20.4053 17.7661 20.871 17.7661C21.3367 17.7661 21.3884 17.7144 21.3884 17.2487C21.3884 16.7829 21.3367 16.7312 20.871 16.7312C20.4053 16.7312 20.3535 16.7829 20.3535 17.2487Z"
        fill="#D4AE62"
      />
      <path
        d="M24.2687 16.8519C24.1997 16.9037 24.1479 17.1452 24.1479 17.3694C24.1479 17.6971 24.2342 17.7661 24.6654 17.7661C25.1311 17.7661 25.1829 17.7144 25.1829 17.2487C25.1829 16.8174 25.1139 16.7312 24.7861 16.7312C24.5619 16.7312 24.3204 16.7829 24.2687 16.8519Z"
        fill="#D4AE62"
      />
      <path
        d="M20.3535 21.5607C20.3535 22.0264 20.4053 22.0781 20.871 22.0781C21.3367 22.0781 21.3884 22.0264 21.3884 21.5607C21.3884 21.095 21.3367 21.0432 20.871 21.0432C20.4053 21.0432 20.3535 21.095 20.3535 21.5607Z"
        fill="#D4AE62"
      />
      <path
        d="M24.1827 21.5092C24.2172 21.8887 24.3379 22.0094 24.7174 22.0439C25.1313 22.0956 25.1831 22.0439 25.1831 21.5782C25.1831 21.0952 25.1313 21.0435 24.6484 21.0435C24.1827 21.0435 24.1309 21.0952 24.1827 21.5092Z"
        fill="#D4AE62"
      />
      <path
        d="M11.0391 23.9067V27.46L11.4358 27.3737C12.5914 27.0805 14.9373 26.7183 16.2999 26.5975L17.8523 26.4768V23.4583V20.4398L14.4543 20.388L11.0391 20.3535V23.9067ZM16.2999 23.8895V25.7868L14.5923 25.8386C13.4884 25.8731 12.8157 25.8213 12.6604 25.6834C12.4879 25.5454 12.419 25.0279 12.419 23.8032C12.419 22.8891 12.4707 22.0784 12.5397 22.0094C12.6087 21.9576 13.4711 21.9231 14.4715 21.9404L16.2999 21.9921V23.8895Z"
        fill="#D4AE62"
      />
      <path
        d="M13.2812 23.8896V25.0107H14.4024H15.5236V23.8896V22.7684H14.4024H13.2812V23.8896Z"
        fill="#D4AE62"
      />
      <path
        d="M14.9719 27.6841C12.7123 27.9946 9.46955 28.8052 7.12373 29.6504C5.24363 30.3231 1.50067 31.91 1.58691 31.9962C1.62141 32.0307 2.50109 31.8238 3.53601 31.5478C6.14056 30.8578 10.8494 29.9092 13.454 29.5469C16.4208 29.133 22.5785 29.133 25.5108 29.5469C28.3396 29.9436 31.6341 30.5991 34.5663 31.3235C35.8772 31.6513 36.9811 31.8927 37.0156 31.8582C37.1364 31.7548 33.6177 30.2369 31.6858 29.5642C29.3227 28.7362 26.2007 27.9946 23.8377 27.6841C21.4919 27.3736 17.2659 27.3736 14.9719 27.6841Z"
        fill="#D4AE62"
      />
    </svg>
  );
};

export default IndustrySector;
