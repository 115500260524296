import React from "react";
import "./Media.scss";
import Header from "../Shared/header/Header";
import { Media2024 } from "./Media2024/Media2024";
import { Link } from "react-router-dom";

const Media = () => {
  return (
    <>
      <Header />
      <div className="date-container">
        <div className="media">
          <Link to={"/media/2024"}>
            <span>2024</span>
          </Link>
        </div>
        <div className="media">
          <Link>
            <span>2023 - 2022</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Media;
